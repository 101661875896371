<template>
    <b-card no-body class="card-statistics">
      <!-- <b-card-header>
        <b-card-title>Statistics</b-card-title>
      </b-card-header> -->
      <b-card-body class="statistics-body"> 
        <div v-if="this.groupCardFlag" class="spinnerCentered">
          <multiChannelSpinner />
        </div>
        <div v-else>
          <b-row v-if="statisticsItemsData.length === 0" class="iconCentered">
            <b-col cols="12"><div class="mt-2"><h5>No Data Found.</h5></div></b-col>
         </b-row>
         <b-row v-else> 
           <b-col
             v-for="item in statisticsItemsData"
             :key="item.subtitle"
             class="mb-2 mb-md-0"
             :class="item.customClass"
           >
             <div class="text-center mb-1">
               <b-avatar size="48" :variant="item.color">
                 <span
                   v-if="item.icon == 'currency-rupee'"
                   style="font-size: 22px;"
                 >
                   ₹</span
                 >
                 <span v-else><feather-icon size="24" :icon="item.icon"/></span>
               </b-avatar>
             </div>
             <b-media class="text-center" no-body>
               <b-media-body class="my-auto">
                 <h4 class="font-weight-bolder mb-0">
                   {{ item.title }}
                 </h4>
                 <b-card-text class="font-small-3 mb-0">
                   {{ item.subtitle }}
                 </b-card-text>
               </b-media-body>
             </b-media>
           </b-col>
         </b-row>
        </div>
    
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  } from "bootstrap-vue";
  import commaNumber from "comma-number";
  import millify from "millify";
  import projectsMap from "@/@core/utils/projectDetailMap";
  import { f } from "vue-phone-number-input/dist/vue-phone-number-input.common";
  import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardTitle,
      BCardText,
      BCardBody,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
      multiChannelSpinner
    },
    props: {
      iconType:{
        type:String
      },
      businessType:{
        type:Array
      },
      groupCardFlag:{type:Boolean},
      adRunningError:{type:String},
      summaryData: {
        type: Object,
        default: () => {
          return {
          CRMtotalInvalid:0,
          CRMqualifiedInvalid: 0,
          CRMadmissionInvalid: 0,
          CRMtotal: 0,
          CRMadmission: 0,
          CRMqualified: 0
          };
        },
      },
    },
    computed: {
      statisticsItemsData() {
          let statisticsItems = [];
  let summaryData = this.summaryData;
  
  // let order = [ 'spends', 'sales','orders','impressions', 'clicks'];
  const order = ["CRMqualified", "CRMadmission","CRMtotal", "CRMadmissionInvalid", "CRMqualifiedInvalid", "CRMtotalInvalid"];
  
  for (let i = 0; i < order.length; i++) {
    const dataKey = order[i];
    let title = dataKey;
    title = title.replace("_", " ").toLowerCase();
    if (summaryData[dataKey] !== undefined) {
      let dataToPush = this.getFormattedText(summaryData[dataKey], title);
      if (dataToPush) {
        statisticsItems.push(dataToPush);
      }
    }
  }
  
  return statisticsItems;
        },
    },
    data() {
      return {
        projectsMap:projectsMap
      };
    },
    methods: {
    getFormattedText(text, field) {
    field = field.toLowerCase();
     if (field === "crmadmission") {
      return {
        icon: "BookIcon",
        color: "light-info",
        title: millify(text),
        subtitle: "CRM Admission",
        customClass: "mb-2 mb-xl-0",
      };
    } 
   else if (field === "crmqualified") {
      return {
        icon: "TargetIcon",
        color: "light-warning",
        title: millify(text),
        subtitle: "CRM Qualified",
        customClass: "mb-2 mb-xl-0",
      };
    }
    else if (field === "crmtotal") {
      return {
        icon: "BriefcaseIcon",
        color: "light-pink",
        title: millify(text),
        subtitle: "CRM Total",
        customClass: "mb-2 mb-xl-0",
      };
    }
    else if (field === "crmqualifiedinvalid") {
      return {
        icon: "TargetIcon",
        color: "light-info",
        title: millify(text),
        subtitle: "CRM Qualified Invalid",
        customClass: "mb-2 mb-xl-0",
      };
    }
    else if (field === "crmadmissioninvalid") {
      return {
        icon: "BookIcon",
        color: "light-warning",
        title: millify(text),
        subtitle: "CRM Admission Invalid",
        customClass: "mb-2 mb-xl-0",
      };
    }
    else if (field === "crmtotalinvalid") {
      return {
        icon: "BriefcaseIcon",
        color: "light-pink",
        title: millify(text),
        subtitle: "CRM Total Invalid",
        customClass: "mb-2 mb-xl-0",
      };
    }
  },
  
    },
  };
  </script>
  
  <style>
  .iconCentered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  </style>